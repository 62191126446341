<!-- <table class="min-w-full divide-y divide-gray-200">
  <thead class="border-none">
  <p class="text-3xl font-bold mb-2 text-center sm:text-left">{{title}}</p>
  </thead>
  <tbody class="border-none">
  <tr *ngFor="let task of actualPageTask; let i = index"
      class="flex items-center bg-white odd:bg-tertiary-200 even:bg-white py-2">
    <td [ngClass]="{'text-tertiary-100': task.status == 'sin estado',
                          'text-pending-600': task.status == 'en curso',
                          'text-primary-500': task.status == 'completa'}"
        class="w-1/12 text-center text-5xl p-0">
      &bull;
    </td>
    <td class="w-10/12">
      <p class="hover:text-blue-dark text-2xl">{{ task.name }}</p>
    </td>
    <td class="w-1/12">
      <p class="text-sm text-grey-dark text-2xl mr-2">{{ task.created_at |date:'d/MM' }}</p>
    </td>
  </tr>
  </tbody>
  <tfoot class="flex justify-between items-center border-none">
  <div>
    <button (click)="previousPage()" class="paginator-button" *ngIf="actualPage > 1">
      <svg xmlns="http://www.w3.org/2000/svg" class="rotate-180" width="24" height="24" viewBox="0 0 24 24"
           stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M9 6l6 6l-6 6"></path>
      </svg>
    </button>
  </div>
  <div *ngIf="totalPages > 1">
    <p class="hover:text-blue-dark text-base">Página {{actualPage}} de {{totalPages}}</p>
  </div>
  <div>
    <button (click)="nextPage()" class="paginator-button" *ngIf="this.tasks.length > ((this.actualPage) * this.limit)">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="24" height="24"
           viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
           stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M9 6l6 6l-6 6"></path>
      </svg>
    </button>
  </div>
  </tfoot>
</table> -->

<div class="w-full border-4 border-black flex flex-col">
  <div class="bg-primary-500">
    <p class="font-semibold py-6 text-center text-xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-4xl">
      {{ title }}
    </p>
  </div>

  <div class="w-full flex-grow">
    <table class="min-w-full">
      <tbody class="bg-white">
        <!-- Encabezados de la tabla -->
        <tr class="bg-secondary-950 text-primary-500">
          <td class="py-3 px-1 text-center">Estado</td>
          <td class="py-3 px-1 text-left">Tarea</td>
          <td class="py-3 px-1 -mr-2 text-left">Fecha</td>
        </tr>

        <!-- Filas de datos -->
        <tr *ngFor="let task of actualPageTask; let i = index">
          <td [ngClass]="{
            'text-tertiary-100': task.status == 'sin estado',
            'text-pending-600': task.status == 'en curso',
            'text-primary-500': task.status == 'completa'
          }" class="text-center py-1">
            <p class="text-5xl -mt-2 p-0">&bull;</p>
          </td>
          <td class="py-3 px-1 text-left">
            <p class="hover:text-blue-dark text-xl">{{ task.name }}</p>
          </td>
          <td class="text-left">
            <p class="text-sm text-grey-dark mr-2">{{ task.created_at | date: 'dd/MM' }}</p>
          </td>
        </tr>
        <!-- Agregar más filas de datos aquí -->
      </tbody>
    </table>
  </div>

  
</div>
<tfoot class="flex justify-between items-center bg-white border-t border-secondary-950 py-3">
  <div>
    <button
      (click)="previousPage()"
      class="paginator-button"
      *ngIf="actualPage > 1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="rotate-180"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M9 6l6 6l-6 6"></path>
      </svg>
    </button>
  </div>
  <div *ngIf="totalPages > 1">
    <p class="hover:text-blue-dark text-base">
      Página {{ actualPage }} de {{ totalPages }}
    </p>
  </div>
  <div>
    <button
      (click)="nextPage()"
      class="paginator-button"
      *ngIf="this.tasks.length > this.actualPage * this.limit"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-chevron-right"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M9 6l6 6l-6 6"></path>
      </svg>
    </button>
  </div>
</tfoot>

