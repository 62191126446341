import {Component, Input, OnInit} from '@angular/core';
import { Note } from 'src/app/models/Note';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent {
  actualPageNote: Note[] = [];
  actualPage = 1;
  totalPages = 0;
  @Input() notes: Note[] = [];
  @Input() limit: number = 5;
  @Input() title: string = "Notas";

  ngOnInit() {
    this.actualPageNote = this.notes.slice(0, this.limit);
    this.totalPages = Math.ceil(this.notes.length / this.limit)
  }

  nextPage(){
    const startIndex = (this.actualPage) * this.limit;
    if (this.notes.length > startIndex) {
      this.actualPage += 1;
      let actualPage = (this.actualPage - 1) * this.limit;
      let limit = this.actualPage* this.limit;
      this.actualPageNote = this.notes.slice(actualPage, limit);
    }
  }

  previousPage(){
    if (this.actualPage > 1) {
      this.actualPage -= 1;
      let actualPage = (this.actualPage - 1) * this.limit;
      let limit = this.actualPage* this.limit;
      this.actualPageNote = this.notes.slice(actualPage, limit);
    }
  }
}
