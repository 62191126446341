<table class="w-full border border-secondary-950">
  <thead class="border border-secondary-950">
  <tr class="bg-primary-500">
    <th id="month-calendar" class="" colspan="7">
      <div class="flex justify-between items-center py-2 text-center">
        <button (click)="previousMonth()" *ngIf="showChangeMonth"
                class="ml-4 arrow-button" id="calendar-back-button">
          <svg xmlns="http://www.w3.org/2000/svg" class="rotate-180" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M9 6l6 6l-6 6"></path>
          </svg>
        </button>
        <span class="text-2xl w-full">{{actualName}}</span>
        <button (click)="nextMonth()" *ngIf="showChangeMonth"
                class="mr-4 arrow-button" id="calendar-next-button">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M9 6l6 6l-6 6"></path>
          </svg>
        </button>
      </div>

    </th>
  </tr>
  <tr class="border border-secondary-950">
    <th class="week_name">LUN</th>
    <th class="week_name">MAR</th>
    <th class="week_name">MIÉ</th>
    <th class="week_name">JUE</th>
    <th class="week_name">VIE</th>
    <th class="week_name">SÁB</th>
    <th class="week_name">DOM</th>
  </tr>
  </thead>
  <tbody class="text-center">
    <tr *ngFor="let week of calendarWeeks">
      <td
        [ngClass]="{
          'selectedDay': day.id === selectedDay && showSelectedDay,
          'noMonthDay' : !day.monthDay,
          'monthDay' : day.monthDay,
          'dayHover': showDayHover,
          'selectedDays': (selectedDates.indexOf(day.id) !== -1 && ( day.id !== selectedDay || !showSelectedDay)),
          'hover:cursor-pointer': ableDayClick
        }"
        (click)="dayClick(day.id)"
        *ngFor="let day of week" [id]="day.id">
          {{day.day}}
      </td>
    </tr>
  </tbody>
</table>

