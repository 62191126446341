import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthStateService } from "../../services/auth/auth-state.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authStateService: AuthStateService,
    private router: Router
  ) {}

  /**
   * Interceptor que se encarga de manejar el control de errores de las peticiones
   *
   * Aquí se gestiona que hacer en casa de cada tipo de error devuelto por nuestra api.
   *
   * Si el error es 401, cerramos la sesión del usuario y redirigimos a login.
   *
   * Por último devolvemos el error.
   *
   * @param req
   * @param next
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        /*if (req.url.includes("graph.facebook.com")) {
          if (error.error.error.type == "OAuthException") {
            this.authStateService.setFacebookUser(null);
          }
        } else {*/
          if (error.status === 401) {
            //this.authStateService.removeToken();
            this.authStateService.setSignedUser(null);
            this.router.navigate(["/"]);
          }
        /*}*/
        return throwError(() => error);
      })
    );
  }
}
