<div class="w-full border-4 border-black flex flex-col">
    <div class="bg-primary-500">
      <p class="font-semibold py-6 text-center text-xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-4xl">
        {{ title }}
      </p>
    </div>
  
    <div class="w-full flex-grow">
      <table class="min-w-full">
        <tbody class="bg-white">
          <!-- Encabezados de la tabla -->
          <tr class="bg-secondary-950 text-primary-500">
            <td class="py-3 px-1 text-center">Nota</td>
            <!-- <td class="py-3 px-1 text-left">Tarea</td> -->
            <td class="py-3 px-1 -mr-2 text-left">Fecha</td>
          </tr>
  
          <!-- Filas de datos -->
          <tr *ngFor="let note of actualPageNote; let i = index">
            <td class="py-3 px-1 text-left">
              <p class="hover:text-blue-dark text-xl">{{ note.note }}</p>
            </td>
            <td class="text-left">
              <p class="text-sm text-grey-dark mr-2">{{ note.date | date: 'dd/MM' }}</p>
            </td>
          </tr>
          <!-- Agregar más filas de datos aquí -->
        </tbody>
      </table>
    </div>
  
    
  </div>
  <tfoot class="flex justify-between items-center bg-white border-t border-secondary-950 py-3">
    <div>
      <button
        (click)="previousPage()"
        class="paginator-button"
        *ngIf="actualPage > 1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="rotate-180"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M9 6l6 6l-6 6"></path>
        </svg>
      </button>
    </div>
    <div *ngIf="totalPages > 1">
      <p class="hover:text-blue-dark text-base">
        Página {{ actualPage }} de {{ totalPages }}
      </p>
    </div>
    <div>
      <button
        (click)="nextPage()"
        class="paginator-button"
        *ngIf="this.notes.length > this.actualPage * this.limit"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-chevron-right"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M9 6l6 6l-6 6"></path>
        </svg>
      </button>
    </div>
  </tfoot>