import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthStateService} from '../services/auth/auth-state.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(private authStateService: AuthStateService,private router: Router) {}

  /**
   * Guard para el control de acceso a determinadas rutas en función de sí hay usuario logged in o no.
   *
   * Si el usuario esta logged in continua, si no redirige a /login.
   *
   * @param route Ruta activada.
   * @param state Estado de la ruta.
   */

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authStateService.isSignedIn()) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }



}
