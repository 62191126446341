import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  calendarWeeks: any = [];
  actualName = "";
  actualMonth: string | null = null;

  selectedDay: string = "";

  @Input() selectedDates: string[] = [];

  @Input() showSelectedDay: boolean = false;
  @Input() ableDayClick: boolean = false;
  @Input() returnSelectedDayAtStart: boolean = false;
  @Input() showChangeMonth: boolean = false;
  @Input() showDayHover: boolean = false;
  @Output() clickDay: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    let date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    this.actualMonth = currentMonth+"-"+currentYear;
    this.selectedDay = currentYear+"-"+currentMonth.toString().padStart(2, "0")+"-"+date.getDate().toString().padStart(2, "0");
    if (this.ableDayClick && this.returnSelectedDayAtStart) {
      this.clickDay.emit(currentYear+"-"+currentMonth.toString().padStart(2, "0")+"-"+date.getDate().toString().padStart(2, "0"));
    }
    this.createCalendar(currentYear, currentMonth);
  }

  createCalendar(year: number, month: number){
    const date = new Date(year, month - 1, 1); // Creamos la fecha con el día del mes y el año pasados
    let firstDayOfWeek = date.getDay(); // Día de la semana del primer día del mes
    const daysInMonth = new Date(year, month, 0).getDate(); // Número de días en el mes
    const finalDate = new Date(year, month - 1, daysInMonth);

    let extraDays = 0;
    switch (firstDayOfWeek){
      case 0:
        extraDays = 6;
        break;
      case 1:
        extraDays = 0;
        break;
      case 2:
        extraDays = 1;
        break;
      case 3:
        extraDays = 2;
        break;
      case 4:
        extraDays = 3;
        break;
      case 5:
        extraDays = 4;
        break;
      case 6:
        extraDays = 5;
        break;
      default:
        extraDays = 0;
        break;
    }

    let numRows = Math.ceil(((daysInMonth+extraDays)) / 7); // Número de filas necesarias

    var meses = [
      "ENERO",
      "FEBRERO",
      "MARZO",
      "ABRIL",
      "MAYO",
      "JUNIO",
      "JULIO",
      "AGOSTO",
      "SEPTIEMBRE",
      "OCTUBRE",
      "NOVIEMBRE",
      "DICIEMBRE",
    ];

    this.actualName = meses[date.getMonth()] +" "+ year;

    let currentDay = 1; // Día actual del mes para recorrerlos y pintarlos.
    let calendarArr = [];
    for (let i = 0; i < numRows; i++) {
      let weekRow = [];
      for (let j = 1; j < 8; j++) {
        //if (i === 0 && j < firstDayOfWeek) {
        if ((i === 0 && j < firstDayOfWeek) || i === 0 && firstDayOfWeek == 0 && j < 7) {
          let index = extraDays - (j-1);
          let startDate = new Date(date);
          startDate.setDate(startDate.getDate() - index)
          const anno = startDate.getFullYear();
          const mes = String(startDate.getMonth() + 1).padStart(2, '0'); // Suma 1 al mes porque los meses se indexan desde 0
          const day = String(startDate.getDate()).padStart(2, '0');
          weekRow.push({
            day: startDate.getDate(),
            id: anno+"-"+mes+"-"+day,
            monthDay: false
          });
        } else if (currentDay > daysInMonth) {
          finalDate.setDate(finalDate.getDate() + 1)
          const anno = finalDate.getFullYear();
          const mes = String(finalDate.getMonth() + 1).padStart(2, '0'); // Suma 1 al mes porque los meses se indexan desde 0
          const day = String(finalDate.getDate()).padStart(2, '0');
          weekRow.push({
            day: finalDate.getDate(),
            id: anno+"-"+mes+"-"+day,
            monthDay: false
          });
        } else {
          weekRow.push({
            day: currentDay,
            id: year+"-"+month.toString().padStart(2, "0")+"-"+currentDay.toString().padStart(2, "0"),
            monthDay: true
          });
          currentDay++;
        }
      }
      calendarArr.push(weekRow);
    }
    this.calendarWeeks = calendarArr;
  }

  previousMonth(){
    const parts = this.actualMonth!.split('-');
    const month = parseInt(parts[0]);
    const year = parseInt(parts[1]);
    const previousMonth = month === 1 ? 12 : month - 1;
    const previousYear = month === 1 ? year - 1 : year;
    this.actualMonth = previousMonth+"-"+previousYear;
    this.createCalendar(previousYear, previousMonth);
  }

  nextMonth(){
    const parts = this.actualMonth!.split('-');
    const month = parseInt(parts[0]);
    const year = parseInt(parts[1]);
    const nextMonth = month === 12 ? 1 : month + 1;
    const nextYear = month === 12 ? year + 1 : year;
    this.actualMonth = nextMonth+"-"+nextYear;
    this.createCalendar(nextYear, nextMonth);
  }

  dayClick(date: string){
    this.selectedDay = date;
    if (this.ableDayClick) {
      this.clickDay.emit(date);
    }
  }
}
