import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {
  transform(timeString: string): string {
    // Asumiendo que la cadena de tiempo está en el formato "hh:mm:ss"
    const [hours, minutes, seconds] = timeString.split(':');
    return `${hours}h ${minutes}m`;
  }
}
