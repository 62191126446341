import {Component, Input, OnInit} from '@angular/core';
import {Task} from "../../models/Task";

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  actualPageTask: Task[] = [];
  actualPage = 1;
  totalPages = 0;
  @Input() tasks: Task[] = [];
  @Input() limit: number = 5;
  @Input() title: string = "Tareas";

  ngOnInit() {
    this.actualPageTask = this.tasks.slice(0, this.limit);
    this.totalPages = Math.ceil(this.tasks.length / this.limit)
  }

  nextPage(){
    const startIndex = (this.actualPage) * this.limit;
    if (this.tasks.length > startIndex) {
      this.actualPage += 1;
      let actualPage = (this.actualPage - 1) * this.limit;
      let limit = this.actualPage* this.limit;
      this.actualPageTask = this.tasks.slice(actualPage, limit);
    }
  }

  previousPage(){
    if (this.actualPage > 1) {
      this.actualPage -= 1;
      let actualPage = (this.actualPage - 1) * this.limit;
      let limit = this.actualPage* this.limit;
      this.actualPageTask = this.tasks.slice(actualPage, limit);
    }
  }

}
