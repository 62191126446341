<header class="bg-secondary-950">
  <nav class="flex">
    <div class="bg-secondary-950 hover:cursor-pointer" routerLink="/dashboard">
      <img src="/assets/img/logo.png" alt="Artistafy" width="115"/>
    </div>
    <div class="flex w-3/6 bg-primary-500 h-17 mx-auto items-center justify-center">
      <input [(ngModel)]="searchTerm" (keyup.enter)="onSearch()"
             type="text"
             class="hidden sm:block w-full h-full text-3xl bg-transparent border-none text-center placeholder-secondary-800 focus:outline-none"
             placeholder="...">
      <button  (click)="onSearch()" class="hidden sm:block h-17 mr-2 bg-transparent border-none text-black" aria-label="...">
        <svg class="h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
        </svg>
      </button>
      <button (click)="mobileModal = true" class="block sm:hidden h-17 mr-2 bg-transparent border-none text-black" aria-label="Buscar">
        <svg class="h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
        </svg>
      </button>
    </div>
    <div class="w-2/4 bg-secondary-950 h-17"></div>
    <button routerLink="/dashboard/clientes/crear" class="flex w-2/4 bg-primary-500 h-17 mx-auto items-center justify-center">
      <p class="hidden sm:block text-3xl mr-10">Nuevo cliente</p>
      <svg class="h-10 text-black mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
      </svg>
    </button>
  </nav>
</header>

<main class="pb-20">
  <router-outlet></router-outlet>
</main>

<div *ngIf="mobileModal" class="absolute top-0 bottom-0 left-0 right-0 bg-secondary-950 opacity-95 flex items-center">
  <div class="absolute top-5 right-5">
    <button (click)="mobileModal = false" class="mr-2">
      <svg class="h-8 w-8 text-primary-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </button>
  </div>
  <div class="w-full flex items-center justify-center">
    <input
      [(ngModel)]="searchTerm" (keyup.enter)="onSearch()"
      type="text"
      class="h-10 px-4 py-2 text-lg bg-white border border-primary-500 focus:outline-none focus:border-primary-500"
      placeholder="..."
    />
    <button (click)="onSearch()">
      <svg class="h-6 w-6 text-primary-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 21l-4-4m-1-7a7 7 0 11-14 0 7 7 0 0114 0z"
        ></path>
      </svg>
    </button>
  </div>
</div>
