import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent {
  searchTerm: string = '';
  mobileModal: boolean = false;

  constructor(private router: Router) { }

  onSearch(){
    if (this.searchTerm) {
      this.mobileModal = false;
      // Redirige a la página de resultados de búsqueda y pasa el término como parámetro de ruta
      this.router.navigate(['/dashboard/resultados-busqueda', this.searchTerm]);
    }
  }
}
