import {Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import {SignedUser} from '../../../models/SignedUser';
// import { FaceBookUser } from 'src/app/models/facebook/FacebookUser';

export const LOCAL_STORAGE_USER_KEY = 'user_BS1_21072023';
// export const LOCAL_STORAGE_FACEBOOK_KEY = 'facebook_BS1_21072023';


@Injectable({
  providedIn: 'root'
})
export class AuthStateService {




  /**
   * Estado de autenticación true o false.
   *
   * @private
   */
                                                                         // @ts-ignore
  private signedUser = new BehaviorSubject<SignedUser | null>(JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY)));
  signedUserState = this.signedUser.asObservable();

  /*private facebookUser = new BehaviorSubject<FaceBookUser | null>(JSON.parse(localStorage.getItem(LOCAL_STORAGE_FACEBOOK_KEY)));
  signedFacebookState = this.facebookUser.asObservable();*/


  setSignedUser(signedUser: SignedUser | null){
    if (signedUser != null) {
      const signedUserJSON = JSON.stringify(signedUser);
      localStorage.setItem(LOCAL_STORAGE_USER_KEY, signedUserJSON);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
    }
    this.signedUser.next(signedUser);
  }

  /*setFacebookUser(facebookUser: FaceBookUser | null){
    if (facebookUser != null) {
      const facebookUserJSON = JSON.stringify(facebookUser);
      localStorage.setItem(LOCAL_STORAGE_FACEBOOK_KEY, facebookUserJSON);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_FACEBOOK_KEY);
    }
    this.facebookUser.next(facebookUser);
  }*/

  isSignedIn(){
    const token = localStorage.getItem(LOCAL_STORAGE_USER_KEY);
    return !!token;
  }

  /*isFacebookSignedIn(){
    const token = localStorage.getItem(LOCAL_STORAGE_FACEBOOK_KEY);
    return !!token;
  }*/

  getToken(){
    // @ts-ignore
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
    if (token)  {
      return token.token;
    } else {
      return "";
    }
  }

  /*getFacebookToken(){
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_FACEBOOK_KEY));
    if (token)  {
      return token.authResponse.accessToken;
    } else {
      return "";
    }
  }*/

  havePermissionsArrayTo(permissions: string[]){
    if (permissions.length) {
      // @ts-ignore
      return this.signedUser.value.permissions.some(elemento => permissions.includes(elemento));
    } else {
      return true;
    }
  }

  havePermissionsTo(permissionString: string){
    if (permissionString) {
      // @ts-ignore
      return this.signedUser.value.permissions.some(elemento => elemento == permissionString);
    } else {
      return true;
    }
  }
}
