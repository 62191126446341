import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { AuthStateService } from "../services/auth/auth-state.service";
import { HttpParams } from "@angular/common/http";

@Injectable()
export class HeadersInterceptors implements HttpInterceptor {
  constructor(
    private router: Router,
    private authStateService: AuthStateService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let headers = req.headers;
    let modifiedReq = req;

    headers = headers.set("X-Requested-With", "XMLHttpRequest");

    /*if (req.url.includes("graph.facebook.com")) {
      const facebookToken = this.authStateService.getFacebookToken();

      let params = new HttpParams();
      params = params.append("access_token", facebookToken); // Cambia 'access_token' al nombre del parámetro esperado por la API

      // Clonar la solicitud con los parámetros actualizados
      modifiedReq = req.clone({
        params: params,
      });
    } else {*/
      const token = this.authStateService.getToken();
      if (token) {
        headers = headers.set("Authorization", "Bearer " + token);
      }

      // Clonar la solicitud con los encabezados actualizados
      modifiedReq = req.clone({ headers });
    /*}*/

    // Continuar con la solicitud modificada
    return next.handle(modifiedReq);
  }
}
