import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CalendarComponent} from "./calendar/calendar.component";
import {NotesComponent} from "./notes/notes.component";
import {TasksComponent} from "./tasks/tasks.component";
import { TimeFormatPipe } from './pipes/time-format.pipe';



@NgModule({
  declarations: [
    CalendarComponent,
    NotesComponent,
    TasksComponent,
    TimeFormatPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CalendarComponent,
    NotesComponent,
    TasksComponent,
    TimeFormatPipe
  ]
})
export class ComponentsModule { }
