import {Injectable} from '@angular/core';
import {HttpHandler, HttpRequest, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";


@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private toast: ToastrService) { }

  /**
   * Interceptor encarado de mostrar un toast por cada mensaje de error que genere nuestra api.
   *
   * Genera un html para el toast con los datos del error y lo muestra.
   *
   * Por último devuelve el error.
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let toastErrorText = "";
        if (error.error) {
          error.error.message && (toastErrorText += "<p class='text-sm'>"+error.error.message+"</p>");
        }
        if (error.error.errors) {
          toastErrorText = "<ul class='max-w-md space-y-1 list-disc list-inside mt-2'>";
          for (const key of Object.keys(error.error.errors)) {
            for (const errorText of error.error.errors[key]) {
              if (errorText != "") {
                toastErrorText += "<li class='text-sm'>"+errorText+"</li>"
              }
            }
          }
          toastErrorText += "</ul>";
        }

        this.toast.error(toastErrorText, 'Error', {
          enableHtml: true,
          closeButton: true,
          timeOut: 10000
        });
        return throwError(() => error);
      })
    );
  }
}
